<template>
  <div class="q-pa-md">
    <q-table
      title="Projects"
      :rows="projects"
      :columns="columns"
      :filter="filter"
      :dense="true"
      :pagination="{ rowsPerPage: 15 }"
    >
      <template v-slot:top>
        <q-btn color="green" label="Create" @click="showDialog()"/>
        <q-space />
        <q-input outlined dense debounce="300" color="primary" v-model="filter">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </template>
      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td key="id" :props="props">
              {{ props.row.id }}
          </q-td>
          <q-td key="projectNumber" :props="props">
            <router-link class="text-primary" :to="'/cctv/' + props.row.id">
              {{ props.row.projectNumber }}
            </router-link>
          </q-td>
          <q-td key="projectName" :props="props">
              {{ props.row.projectName }}
          </q-td>
          <q-td key="projectClient" :props="props">
              {{ props.row.projectClient }}
          </q-td>
          <q-td key="projectLocation" :props="props">
              {{ props.row.projectLocation }}
          </q-td>
        </q-tr>
      </template>
    </q-table>
    <q-dialog
      v-model="projectDialog"
    >
      <q-card style="width: 800px; max-width: 80vw;">
        <div class="q-pa-md">
          <q-form
            @submit="postProject()"
            class="q-pa-md"
          >
            <div class="row q-col-gutter-x-md q-col-gutter-y-md">
                <div class="col-4">
                    <q-input
                        v-model="project.projectNumber"
                        :dense="true"
                        label="Number"
                        outlined
                    />
                </div>
                <div class="col-4">
                    <q-input
                        v-model="project.projectName"
                        :dense="true"
                        label="Name"
                        outlined
                    />
                </div>
                <div class="col-4">
                    <q-input
                        v-model="project.projectLocation"
                        :dense="true"
                        label="Location"
                        outlined
                    />
                </div>
                <div class="col-4">
                    <q-input
                        v-model="project.projectClient"
                        :dense="true"
                        label="Client"
                        outlined
                    />
                </div>
                <div class="col-4">
                    <q-input
                        v-model="project.projectManager"
                        :dense="true"
                        label="Manager(s)"
                        outlined
                    />
                </div>
                <div class="col-4">
                    <q-input
                        v-model="project.projectContractor"
                        :dense="true"
                        label="Contractor(s)"
                        outlined
                    />
                </div>
                <div class="col-4">
                <q-select label="Type" outlined emit-value map-options :dense="true" v-model="project.projectTypeId" :options="projectTypes" />
              </div>
            </div>
            <q-separator spaced />
            <div>
              <q-btn label="Submit" type="submit" :disable="submitDisable" :loading="submitDisable" color="primary"/>
            </div>
          </q-form>
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { useQuasar } from 'quasar'
import axios from '../axios'
import router from '../router/index'

export default {
  components: [
    axios
  ],
  data() {
    return {
      notify: useQuasar(),
      projects: [],
      project: {},
      projectTypes: [],
      projectDialog: false,
      submitDisable: false,
      filter: '',
      columns: [
        { name: 'id', align: 'center', label: 'Id', field: 'id', sortable: true },
        { name: 'projectNumber', label: 'Number', field: 'projectNumber', sortable: true },
        { name: 'projectName', label: 'Name', field: 'projectName', sortable: true },
        { name: 'projectClient', label: 'Client', field: 'projectClient', sortable: true },
        { name: 'projectLocation', label: 'Location', field: 'projectLocation', sortable: true },
      ]
    }
  },
  created() {
    this.getProjects()
    this.getProjectTypes()
  },
  methods: {
    goto(route) {
      router.push(route)
    },
    async getProjects() {
      axios.get('/api/Projects')
        .then(response => {
          this.projects = response.data
        })
    },
    async getProjectTypes() {
      axios
        .get('/api/Projects/Types')
        .then(response => {
          response.data.forEach(element => this.projectTypes.push({ value: element.id, label: element.name }))
        })
    },
    async postProject() {
      this.submitDisable = true
      await axios
        .post('/api/Projects', this.project)
        .then(response => {
          this.project = response.data
          this.projectDialog = false
          this.notify.notify({
            message: 'Work saved',
            color: 'green'
          })
        })
        .catch(error => {
          this.notify.notify({
            message: error.response.data.message,
            color: 'red'
          })
        })
      this.submitDisable = false
      this.getProjects()
    },
    async showDialog() {
      this.project = {}
      this.projectDialog = true
    },
  }
}
</script>